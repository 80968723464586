.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: linear-gradient(to top, #000033, #000066);
  ;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.profile-container {
  width: 150px;
  /* Adjust the width and height as needed */
  height: 150px;
  border: 3px solid;
  border-radius: 50%;
  /* Makes it circular */
  overflow: hidden;
  /* Hides overflow if the image is larger than the container */
}

.profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensures the image covers the circular container */
}

.neon-text {
  color: #ffffff;
  /* White text color */
  text-shadow:
    0 0 5px #00e6e6,
    /* Cyan glow */
    0 0 10px #00e6e6,
    /* Cyan glow */
    0 0 20px #00e6e6,
    /* Cyan glow */
    0 0 30px #00e6e6,
    /* Cyan glow */
    0 0 40px #00e6e6;
  /* Cyan glow */
  font-size: 1em;
}

@keyframes neon-glow {
  from {
    text-shadow:
      0 0 5px #00e6e6,
      0 0 10px #00e6e6,
      0 0 20px #00e6e6,
      0 0 30px #00e6e6,
      0 0 40px #00e6e6;
  }

  to {
    text-shadow:
      0 0 10px #00e6e6,
      0 0 20px #00e6e6,
      0 0 30px #00e6e6,
      0 0 40px #00e6e6,
      0 0 50px #00e6e6;
  }
}

.neon-text-glowing {
  animation: neon-glow 1.5s ease-in-out infinite alternate;
}

.neon-button {
  background-color: rgb(33, 125, 205);
  /* Bright blue, you can change this to match your site's color scheme */
  color: #fff;
  /* White text */
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.7);
  /* Light cyan glow */
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.7), 0 0 20px rgba(0, 255, 255, 0.7), 0 0 30px rgba(0, 255, 255, 0.7);
  transition: all 0.1s ease;
}

.neon-button:hover {
  background-color: #0af;
  /* Slightly lighter blue for hover effect */
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.9), 0 0 25px rgba(0, 255, 255, 0.9), 0 0 35px rgba(0, 255, 255, 0.9), 0 0 45px rgba(0, 255, 255, 0.9);
  transform: scale(1.05);
}
